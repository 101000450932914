<template>
  <div class="footer">
    <div class="logo mb-2">
      <ImageLogo class="d-block text-center" />
      <span>
        <Element v-if="topText?.[0] && (topText?.[0]?.rawText || topText?.[0]?.tagName)" v-bind="topText[0]" />
      </span>
    </div>

    <div class="links">
      <SmartLink v-for="(link, i) in links" :key="i" :to="link.uri"> {{ link.label }}</SmartLink>
    </div>
    <!-- <Element v-bind="bottomText[0]" class="copyright align-center my-3" /> -->
    <div class="copyright text-center my-3">
      <div>Copyright © {{ currentYear }}. All Rights Reserved.</div>
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  links: { type: Array, required: false, default: () => [] },
  topText: { type: Array, required: false, default: () => [] },
  bottomText: { type: Array, required: false, default: () => [] },
});
const date = new Date();
const currentYear = date.toLocaleString("default", { year: "numeric" });
</script>
